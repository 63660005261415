$color-red: #b81d18;
$color_white: #f0f0f0;
$color_black: #202020;
$color_grey: #ebebeb;
$color-blue: #004687;
// $color_yellow: #ffaf10;
// $color_dark_yellow: #6f6624;
// $color_gold: #f1c422;
// $color_dark_gold: #E1BC74;
// $color_green: #16cf97;

// $background-black-light: rgba(0, 0, 0, 0.49);
// $background-primary: #242329;
// $background-secondary: #0f0f0f;
// $background-light-gray: #393b46;
// $background-dark-gray: #31333b;
$background-red: #c00000;
// $background-blue-light: #575d82;
// $background-element: #171717;
// $background-blue: #00485a;
// $background-dark-blue: #122732;
// $background-sky-blue: #0b93b5;
// $background-light-blue:rgba(255, 255, 255, 0.1);
// $font-default-gray: #6a6d7b;
// $background-blue-zone:#1b363b;
// $font-size-default: 0.16rem;

// $border-radius: 0.063rem;

// $background-color: #0a1222;
// $navbar-color: #141f33;
// $color-green: #06a899;

$para-size: 18px;
