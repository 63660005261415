@import "../../assets/css/variable.scss";
@import "../../assets/css/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Cinzel&display=swap");

.headerTitle {
	font-size: 30px;
	font-family: "Cinzel", serif;
	.name {
		color: $color-red;
	}

	.club {
		color: $color_black;
	}
}
