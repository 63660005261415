@import "../../../assets/css/variable.scss";
@import "../../../assets/css/mixins.scss";

.welcomeCard {
	//box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); //5px 5px #888888;

	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
		rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

	padding: 15px;

	// max-height: 700px;
	// overflow: scroll;
	// margin-top: 15px;
	margin-bottom: 15px;
	.heading {
		display: flex;
		justify-content: center;
	}
	h1 {
		font-family: "Cinzel", serif;
		color: $color-black;
	}

	.text {
		color: $color-red;
	}

	p {
		font-family: "Cinzel", serif;
		display: flex;
		justify-content: center;
		color: #000000;
		font-size: larger;
	}

	.thumbnail {
		max-width: 100%;
		max-height: 100%;
	}
}
