@import "../../assets/css/variable.scss";
@import "../../assets/css/mixins.scss";

$title-background-color: $background-red;
$title-color: $color_white;
$menu-item-font-weight: bold;

.navbar {
	font-family: "Cinzel", serif;
	background-color: $color_grey;
}
