@import "../../assets/css/variable.scss";
@import "../../assets/css/mixins.scss";

.footer {
	background-color: $color-black;
	color: $color_white;
	padding: 2em 0;

	font-family: "Cinzel", serif;
	// position: absolute;
	// bottom: 0;
	width: 100%;
}

.footer h4 {
	margin-bottom: 1em;
}

.footer a {
	color: $color_white;
	text-decoration: none;
}

.footer a:hover {
	text-decoration: underline;
}

.footer .container {
	max-width: 1200px;
	margin: 0 auto;
}

.footer .list-inline-item {
	margin-right: 1em;
}
