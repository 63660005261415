// .header {
// 	background-color: black;
// 	color: white;
// 	padding: 20px;

// 	.title {
// 		color: red;
// 	}
// }

.header {
	background-color: black;
	color: white;
	padding: 20px;
	text-align: center;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;

	.title {
		color: red;
		font-size: 1.5rem;
		font-weight: 700;
		text-transform: uppercase;
	}
}
