@font-face {
  font-family: "Cinzel";  
  src: local("Cinzel"),
    url("./assets/fonts/Cinzel/Cinzel-Regular.ttf") format("truetype");
}

/* a{display: block;
padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
font-size: var(--bs-nav-link-font-size);
font-weight: var(--bs-nav-link-font-weight);
color: var(--bs-nav-link-color);
text-decoration: none;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;} */

a.nav-link:focus-visible{
  outline: none;
  
}