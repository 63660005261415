@import "../../../assets/css/variable.scss";
@import "../../../assets/css/mixins.scss";

.comingSoon {
	position: relative; /* mandatory */
	background-color: $color_grey;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); //5px 5px #888888;
	padding: 15px;

	max-height: 700px;
	// overflow: scroll;
	// margin-top: 15px;
	margin-bottom: 15px;
	font-family: "Cinzel", serif;
	.heading {
		display: flex;
		justify-content: center;
	}
	h1 {
		color: $color-red;
	}
}
